#wordle-page {
	background-color: #131313;
	color: white;
	min-height: 100vh;
	width: 100vw;
	box-sizing: border-box;

	display: flex;
	flex-flow: column nowrap;
	justify-content: start;
	align-items:center;
	overflow: hidden;

    padding: 50px 0;

	h1 {
        font-size: 2.5rem;
		text-align: center;
	}

	header{
		width: 90vw;
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
		margin: 0 0 30px 0;
		padding: 0 0 30px 0;
		border-bottom: 1px solid grey;
	}
}
