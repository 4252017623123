#second-assignment {
	.submission {
		label {
			display: block;
			width: 90%;
			margin: 15px auto;
			font-size: 12pt;
		}
		.result {
			width: 100%;
			margin: auto;
		}
		.result > p::before,
		.result > pre::before {
			content: attr(aria-label);
			display: block;
			margin: 5px auto;
			font-family: "Anton", "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
		}
		.result > p,
		.result > pre {
			font-family: "Cutive Mono", monospace;
			width: 90%;
			margin: auto;
			word-break: break-all;
		}
		.syntaxes {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-content: space-between;
		}
		.syntaxes > div {
			margin: 0 auto;
		}
		.syntaxes > div > h5 {
			display: block;
			width: 100%;
			text-align: center;
		}
		.syntaxes > div > p{
			font-size: .8rem;
		}

		form > label > input[type="number"] {
			padding: 15px;
			font-size: 12;
			font-family: "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace";
			border: 1px solid black;
			border-radius: 5px;
			width: 100%;
			box-sizing: border-box;
			margin: 10px auto 0 auto;
		}
		form > label > input[type="number"].warning {
			border-color: orangered;
		}
		p.error,
		form > label > input[type="number"].warning + p {
			font-size: smaller;
			color: orangered;
		}
		.button-row {
			display: flex;
			flex-flow: row wrap;
			align-content: center;
			justify-content: center;
		}
		button.secondary {
			font-size: smaller;
			margin: 10px auto;
			padding: 10px 20px;
			background-color: rgb(116, 116, 116);
		}
		button,
		form > input[type="submit"] {
			display: block;
			background-color: rgb(71, 90, 255);
			border-radius: 5px;
			color: white;
			padding: 10px 40px;
			margin: 10px auto;
			font-size: 12pt;
			font-family: "Anton", "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
			transition: all 0.2s ease-in-out;
			border: none;
		}
		button {
			margin: 10px 5px;
		}
		button:hover,
		form > input[type="submit"]:hover {
			box-shadow: -1px 10px 13px -11px rgba(0, 0, 0, 0.75);
			-webkit-box-shadow: -1px 10px 13px -11px rgba(0, 0, 0, 0.75);
			-moz-box-shadow: -1px 10px 13px -11px rgba(0, 0, 0, 0.75);
		}
	}
}

@media screen and (max-width: 600px) {
	#second-assignment {
		.submission {
			button,
			form > input[type="submit"] {
				font-size: 0.9rem;
			}
		}
		*:not(h1, h2, h3, h4, h5, h6) {
			font-size: 0.9rem;
		}
	}
}