#page-content {
	cursor: none;
	width: 100vw;
	height: 100vh;
	padding: 0 0 1vh 0;
	overflow: hidden;

	display: flex;
	flex-flow: column nowrap;
	justify-content: start;
	align-items: center;

	box-sizing: border-box;

	color: white;

	font-family: "Cutive Mono", monospace;
	background: radial-gradient(circle, transparent 20%, #1e1e1e 20%, #1e1e1e 80%, transparent 80%, transparent) 0% 0% / 104px 104px, radial-gradient(circle, transparent 20%, #1e1e1e 20%, #1e1e1e 80%, transparent 80%, transparent) 52px 52px / 104px 104px,
		linear-gradient(#212121 3px, transparent 3px) 0px -1.5px / 52px 52px, linear-gradient(90deg, #212121 3px, #1e1e1e 3px) -1.5px 0px / 52px 52px #1e1e1e;
	background-size: 104px 104px, 104px 104px, 52px 52px, 52px 52px;
	background-color: #1e1e1e;


	#language-switch{
		position: absolute;
		left:5px;
		top:5px;
		text-indent: -9999px;
		overflow: hidden;
		background-color: transparent;
		border:0;
		outline: 0;
		width: 40px;
		height: 30px;
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
	}

	/* ===== Scrollbar CSS ===== */
	/* Firefox */
	> * {
		scrollbar-width: thin;
		scrollbar-color: #ffffff #000000;
	}

	/* Chrome, Edge, and Safari */
	> *::-webkit-scrollbar {
		width: 5px;
	}

	> *::-webkit-scrollbar-track {
		background: #000000;
	}

	> *::-webkit-scrollbar-thumb {
		background-color: #ffffff;
		border-radius: 10px;
		border: 0px solid #ffffff;
	}

	> *::-webkit-scrollbar-button {
		width: 0px;
	}
	/* ===== ============= ===== */

	/* ====== Cursor  CSS ====== */

	> #pointer {
		background-color: white;
		height: 16px;
		width: 16px;
		border-radius: 0 50% 50% 50%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;
		pointer-events: none;
		background-size: 0%;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-image: url("../../../media/images/icons/left-click.png");
	}
	* {
		cursor: none;
	}
	/* ===== ============= ===== */

	/* Header */
	header {
		position: sticky;
		z-index: 99;
		top: 0;
		width: 90vw;
		border-bottom: 1px solid white;
		padding: 20px;
		background-color: #1e1e1e;
		> button#hamburger {
			display: none;
		}
		/* Nav-bar */
		> nav {
			display: flex;
			flex-flow: row wrap;
			align-content: start;
			justify-content: center;
			align-items: center;
			margin-left: auto;
			width: max-content;
			button {
				background-color: transparent;
				color: white;
				border: 0;
				outline: 0;
				padding: 20px;
				font-size: 1.1rem;
				font-family: "Anton", monospace;
				transition: all 0.2s;
			}

			button::after {
				content: "";
				display: block;
				position: relative;
				left: 50%;
				bottom: 0;
				background-color: transparent;
				width: 4px;
				height: 4px;
				border-radius: 50%;
			}
			button:focus::after {
				background-color: grey;
			}
			button.selected::after {
				background-color: white;
			}
		}
	}

	/* sections */
	> section {
		opacity: 0;
		height: 0px;
		overflow: hidden;
		box-sizing: border-box;
		width: 75vw;
		max-width: 800px;
		overflow-y: auto;
		margin-top: 10px;
	}
	> section.current-info {
		height: auto;
		opacity: 1;
		transition: opacity 0.3s ease;
		min-height: 70vh;
		box-sizing: border-box;
	}

	> #home.current-info {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		height: 100%;
		/* Profile pic */
		> div {
			display: flex;
			flex-flow: row nowrap;
			justify-content: center;
			align-items: center;
			border-radius: 15px;
			> div.image-border {
				> img {
					width: auto;
					height: auto;
					max-height: 70vh;
					max-width: 30vw;
					box-sizing: border-box;
					filter: saturate(0.5) brightness(0.9);
				}
			}
			/* Text content */
			> div.text-content {
				display: flex;
				flex-flow: column nowrap;
				justify-content: center;
				align-items: center;
				margin: 0 0 0 30px;
				> h1 {
					font-size: xx-large;
					width: 100%;
				}
				> p {
					width: 100%;
				}
			}
		}
		/* Immediate contacts list */
		> ul {
			height: max-content;
			position: fixed;
			right: 0;
			bottom: 50%;
			transform: translateY(50%);

			list-style: none;
			padding: 30px 15px;
			box-sizing: border-box;
			border-left: 1px solid rgb(80, 80, 80);

			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			align-items: start;
			margin: 10px 0 30px 0;
			li {
				margin: 2px 0;
				.icon-button {
					color: grey;
				}
				.icon-button:hover {
					background-color: rgb(235, 231, 219);
					> * {
						color: black;
					}
				}
			}
		}
	}
	> #about.current-info {
		padding: 30px;
		> h2 {
			height: 0px;
			overflow: hidden;
			margin: 0;
			padding: 0;
		}
		> div:first-of-type {
			margin: 0 0 70px 0;
		}
		> div:last-of-type {
			margin: 70px 0 0 0;
		}
		> div {
			margin: 70px 0;
			display: flex;
			flex-flow: row;
			justify-content: start;
			> h3 {
				padding: 5px;
				font-size: 1.5rem;
				> span {
					display: block;
					text-align: left;
				}
			}
			> p {
				margin: 0 50px;
				line-height: 1.4rem;
				font-size: 1rem;
				text-align: justify;
				word-break: normal;
				font-weight: 200;
				font-size: 1.1rem;
				color: white;
				> span {
					text-transform: uppercase;
					color: #cfb53b;
					font-family: "Anton";
				}
				> a,
				> a:visited {
					color: white;
				}
			}
		}
	}
	> #projects.current-info,
	> #notes.current-info {
		width: 100vw;
		height: max-content;
		min-height: max-content;
		margin-bottom: auto;
		max-width: 95vw;
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: start;
		> * {
			flex-grow: 0;
			flex-shrink: 0;
			display: inline-block;
		}
	}
	> #contacts.current-info {
		width: 100vw;
		max-width: 100vw;
		> div {
			display: flex;
			flex-flow: row nowrap;
			justify-content: center;
			align-items: center;
			> h2 {
				font-size: 13rem;
				color: #cfb53b;
				line-height: 12rem;
				margin-bottom: auto;
			}
			> ul {
				display: flex;
				flex-flow: column;
				align-items: end;
				list-style: none;
				padding: 100px;
				margin: 300px 0 0 -400px;
				> li {
					font-size: large;
					> a,
					> a:visited {
						color: #cfb53b;
					}
				}
			}
		}
	}
}

@media screen and (orientation: landscape) and (max-width: 1200px) {
	#page-content {
		header {
			padding: 10px;
		}
		> #about.current-info {
			> div {
				> p {
					font-size: 1rem;
				}
			}
		}
		> #projects.current-info,
		> #notes.current-info {
			padding: 0;
		}
		> #contacts.current-info {
			> div {
				flex-flow: column;
				> h2 {
					font-size: 7rem;
					line-height: 7rem;
					margin-left: auto;
					text-align: right;
					padding: 0 50px 0 0;
				}
				> ul {
					padding: 50px;
					margin: 0 0 0 auto;
				}
			}
		}
	}
}

@media screen and (orientation: landscape) and (max-width: 800px) {
	#page-content {
		> #about.current-info {
			padding-left: 0;
			padding-right: 0;
			width: 90vw;
			> div {
				> h3 {
					font-size: 1rem;
				}
				> p {
					font-size: 0.8rem;
					> br {
						display: none;
					}
				}
			}
		}
		> #contacts.current-info {
			> div {
				flex-flow: column;
				> h2 {
					font-size: 5rem;
					line-height: 5rem;
				}
				> ul {
					align-items: center;
					> li {
						text-align: right;
						margin-left: auto;
						font-size: 0.9rem;
					}
				}
			}
		}
	}
}

@media screen and (orientation: landscape) and (max-width: 490px) {
	#page-content {
		header {
			> nav {
				button {
					font-size: 0.7rem;
				}
			}
		}
		> #contacts.current-info {
			> div {
				padding-bottom: 20px;
				> h2 {
					padding: 20px;
					font-size: 3rem;
					line-height: 3rem;
				}
				> ul {
					padding: 20px;
					> li {
						font-size: 0.7rem;
					}
				}
			}
		}
	}
}

@media screen and (orientation: portrait) and (max-width: 900px) {
	#page-content {
		> header {
			width: 100%;
			border-bottom: 0;
			height: 0px;
			padding: 0;
			margin: 0;
			> button#hamburger {
				display: block;
				position: absolute;
				z-index: 999;
				top: 20px;
				right: 20px;
				background-color: transparent;
				border: 0;
				outline: 0;
				font-size: 0px;
				> span {
					width: 20px;
					height: 3px;
					border-radius: 5px;
					display: block;
					margin: 3px;
					background-color: grey;
					transition: all 0.15s ease-in-out;
				}
				> span:nth-child(2) {
					transition-delay: .15s;
				}
				> span.open:nth-child(1) {
					transform: translateY(6px) rotate(45deg);
					transition-delay: .15s;
				}
				> span.open:nth-child(2) {
					background-color: transparent;
					transition-delay: 0s;
				}
				> span.open:nth-child(3) {
					transform: translateY(-6px) rotate(-45deg);
					transition-delay: .15s;
				}
			}
			> nav {
				position: absolute;
				top: 0;
				right: 0;
				height: 100vh;
				flex-flow: column;
				justify-content: end;
				align-items: end;
				background-color: #1e1e1e;
				overflow: hidden;
				margin: 0;
				transition: opacity 0.2s ease-in-out;
				> button {
					text-decoration: underline;
				}
			}
			> nav.closed {
				width: 0px;
				opacity: 0;
			}
			> nav.open {
				opacity: 1;
				width: 100vw;
			}
		}
		cursor: default;
		> #pointer {
			display: none;
		}
		* {
			cursor: default;
		}
		> #home.current-info {
			> div {
				flex-flow: column nowrap;
				> div.image-border {
					> img {
						max-width: 70vw;
						max-height: 70vw;
					}
				}
				> div.text-content {
					margin: 0;
					text-align: center;
					max-width: 50vw;
					font-size: 0.9rem;
				}
			}
			> ul {
				flex-flow: row nowrap;
				border-left: 0;
				border-top: 1px solid grey;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%) translateY(0);
				margin: 0;
				width: max-content;
				padding: 10px 30px;
				> li {
					cursor: default;
				}
			}
		}
		> #about.current-info {
			width: 100vw;
			padding: 40px;
			> div {
				width: 100%;
				> h3 {
					width: 100%;
				}
				> p {
					margin: 0 0 0 10px;
					font-size: 0.9rem;
					line-height: 1.1rem;
				}
				flex-flow: column;
				align-items: center;
			}
		}
		> #contacts.current-info {
			> div {
				flex-flow: column;
				align-items: center;
				justify-content: center;
				height: 100%;
				> h2 {
					font-size: 5rem;
					line-height: 5rem;
					margin: 0 0 30px 0;
				}
				> ul {
					padding: 0;
					margin: 0;
				}
			}
		}
	}
}
