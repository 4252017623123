@keyframes tilt-n-move-shaking {
	0% {
		transform: translate(0, 0);
	}
	25% {
		transform: translate(5px, 5px);
	}
	50% {
		transform: translate(0, 0);
	}
	75% {
		transform: translate(-5px, 5px);
	}
	100% {
		transform: translate(0, 0);
	}
}
#wordle-game-board {
	background-color: transparent;
	color: white;

	> #board {
		> p {
			background-color: transparent;
			border: 0;
			outline: 0;
			margin: 0;
			box-sizing: border-box;
			display: flex;
			flex-flow: row nowrap;
			justify-content: center;
			align-items: center;
			> span {
				box-sizing: border-box;
				display: inline-flex;
				flex-flow: column;
				align-items: center;
				justify-content: center;
				text-transform: uppercase;
				font-family: "Anton";
				font-size: 1.5rem;
				padding: 25px;
				margin: 3px;
				width: 70px;
				height: 70px;
				overflow: hidden;
				background-color: transparent;
				border: 3px solid rgb(50, 50, 50);

				transition: all 0.4s ease-in;
			}
			> span:nth-child(2) {
				transition-delay: 0.4s;
			}
			> span:nth-child(3) {
				transition-delay: 0.8s;
			}
			> span:nth-child(4) {
				transition-delay: 1.2s;
			}
			> span:nth-child(5) {
				transition-delay: 1.6s;
			}
			> span.correct {
				background-color: orange;
				border-color: orange;
			}
			> span.exact {
				background-color: green;
				border-color: green;
			}
			> span.miss {
				background-color: rgb(50, 50, 50);
			}
			> span.not-a-word {
				animation: tilt-n-move-shaking 0.1s;
				animation-iteration-count: 1;
			}
		}
	}

	> #keyboard {
		width: 100vw;
		box-sizing: border-box;
		margin: 20px 0;
		> div {
			width: 100%;
			display: flex;
			flex-flow: row nowrap;
			justify-content: center;
			align-items:center;
			> button {
				display: block;
				padding: 15px;
				outline: none;
				border: none;
				margin: 2px;
				border-radius: 5px;
				min-width: 40px;
				font-family: "Cutive Mono", monospace;
				font-weight: bold;
				font-size: 1.1rem;
				background-color: rgb(60, 60, 60);
				transition: all 0.4s ease-in-out;
				color: white;
			}
			> button.used {
				color: white;
				background-color: rgb(30, 30, 30);
			}
			> button.correct {
				background-color: white;
				color: black;
			}
		}
	}
}

@media screen and (max-width: 600px) {
	#wordle-game-board {
		> #board {
			> p > span {
				font-size: 1.2rem;
				width: 40px;
				height: 40px;
			}
		}
		> #keyboard {
			margin: 20px 0;
			> div {
				> button {
					width: 8vw;
					min-width: max-content;
					font-size: 1rem;
				}
			}
		}
	}
}

@media screen and (max-width: 436px) {
	#wordle-game-board {
		> #keyboard {
			margin: 20px 0;
			> div {
				> button {
					padding: 10px;
					font-size: 0.7rem; 
				}
			}
		}
	}
}

@media screen and (max-width: 312px) {
	#wordle-game-board {
		> #board {
			> p > span {
				font-size: 1rem;
				padding: 5px;
			}
		}
		> #keyboard {
			margin: 20px 0;
			> div {
				> button {
					padding: 4px;
					font-size: 0.8rem;
				}
			}
		}
	}
}

@media screen and (max-width: 270px) {
	#wordle-game-board {
		> #board {
			> p > span {
				font-size: .7rem;
				padding: 2px;
				border-width: 1px;
				width: 30px;
				height: 30px;
			}
		}
	}
}
