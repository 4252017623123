@keyframes animate-fade {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
#modal {
	width: min-content;
	> button {
		outline: none;
		background-color: transparent;
		color: white;
		border: 0;
		text-decoration: underline;
		font-family: "Anton";
	}
	> #instruction-modal.false {
		height: 0px;
		width: 0px;
		overflow: hidden;
	}
	> #instruction-modal.true {
		overflow: hidden;
		width: 100vw;
		height: 100vh;

		background-color: transparent;
		backdrop-filter: blur(5px);

		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;

		position: absolute;
		left: 0;
		top: 0;

		animation-duration: 0.2s;
		animation-name: animate-fade;
		animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
		animation-fill-mode: both;

		> #instruction {
			box-sizing: border-box;
			width: 90vw;
			max-width: 700px;
			max-height: 90vh;
			height: min-content;

			padding: 50px;
			border-radius: 10px;
			background-color: #202020;

			display: flex;
			flex-flow: column nowrap;
			justify-content: start;
			align-items: start;

			overflow: auto;

			> button {
				margin: auto;
				outline: none;
				background-color: transparent;
				color: white;
				border: 0;
				text-decoration: underline;
				font-family: "Anton";
				font-size: 1.5rem;
			}

			p {
				margin: 2px 0;
			}

			> ul {
				padding: 0 auto;
			}

			> hr {
				width: 90%;
				margin: 20px 0;
			}

			> div.example {
				padding: 10px 0;
				width: 100%;
				display: flex;
				flex-flow: column nowrap;
				justify-content: start;
				align-items: start;
				> p.row {
					background-color: transparent;
					border: 0;
					outline: 0;
					margin: 0;
					box-sizing: border-box;
					display: flex;
					flex-flow: row nowrap;
					justify-content: center;
					align-items: center;
					> span {
						box-sizing: border-box;
						display: inline-flex;
						flex-flow: column;
						align-items: center;
						justify-content: center;
						text-transform: uppercase;
						font-family: "Anton";
						font-size: 1.5rem;
						padding: 25px;
						margin: 3px;
						width: 70px;
						height: 70px;
						overflow: hidden;
						background-color: transparent;
						border: 3px solid rgb(50, 50, 50);

						transition: all 0.4s ease-in;
					}
					> span.correct {
						background-color: orange;
						border-color: orange;
					}
					> span.exact {
						background-color: green;
						border-color: green;
					}
					> span.miss {
						background-color: rgb(50, 50, 50);
					}
					> span.not-a-word {
						animation: tilt-n-move-shaking 0.25s;
						animation-iteration-count: 1;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 600px) {
	#modal {
		> #instruction-modal.true {
			> #instruction {
				padding: 20px;
				font-size: .9rem;
				> div.example {
					> p.row {
						> span {
							font-size: 1.2rem;
							width: 20px;
							height: 20px;
							padding: 15px;
						}
					}
				}
			}
		}
	}
}
