#Four-O-Four{
    margin:0;
    padding:0;
    border:0;
    height:100vh;
    width: 100vw;
    background-color: black;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    color: white;

    background-image: url(../../../media/images/cat-bodyguard.png);
    background-position: center;
    background-size: auto 100%;
    background-repeat: no-repeat;

    box-sizing: border-box;
    
    font-family: 'Anton';
    >div{
        padding-left: 10vw;
        height: 100vh;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        background-color: rgba(0, 0, 0, .5);
        > a{color: lightblue}
    }
}