.software-verification-page {
	max-width: 800px;
	margin: auto;
	padding: 40px;

	h1:after,
	h2:after {
		content: " ";
		display: block;
		margin: 10px auto;
		border: 0.1px solid black;
	}
	p,
	label,
	ul {
		font-weight: 200;
	}
	#divider{
		margin: 30px 0;
		height: 0px;
		background-color: transparent;
		border-color: transparent;
	}
}
