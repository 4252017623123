body {
	margin: 0;
	padding: 0;
	border:0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
}

html {
	margin: 0;
	padding: 0;
	border:0;
	scroll-behavior: smooth;
}

@font-face {
	font-family: "Cutive Mono";
	src: url("./media/fonts/CutiveMono/CutiveMono-Regular.ttf") format("ttf"), url("./media/fonts/CutiveMono/CutiveMono-Regular.woff2") format("woff2"), url("./media/fonts/CutiveMono/CutiveMono-Regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Anton";
	src: url("./media/fonts/Anton/Anton-Regular.ttf") format("ttf"), url("./media/fonts/Anton/Anton-Regular.woff2") format("woff2"), url("./media/fonts/Anton/Anton-Regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-family: "Anton", sans-serif;
	font-weight: 400;
	font-style: normal;
	text-transform: uppercase;
}
